/* eslint-disable import/order */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Transition from "../components/transition"

import Footer from "../components/Footer"
import Header from "../components/Header"
// import '../styles/index.scss';
import MenuContext from "../context/menu-context"
import getFirebase from "../firebase/firebase"
import withStyles from "@material-ui/core/styles/withStyles"

import { registerUserInRedux } from "../actions/auth"

import { Helmet } from "react-helmet"
import favicon from "../images/favicon.png"
import ParticlesContainer from "./particlesContainer"

const style = theme => ({
  // root: {
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   minHeight: "100vh",
  //   backgroundColor: theme.palette.grey["300"],
  // },
  // main: {
  //   marginTop: theme.spacing(0),
  //   marginBottom: theme.spacing(2),
  //   // minHeight: "100%",
  // },
  // footer: {
  //   padding: theme.spacing(0),
  //   // marginTop: "auto",
  //   backgroundColor: "white",
  //   // flex: 0,
  // },
  //Fixed option
  // root: {
  //   margin: 0,
  //   overflow: "hidden",
  // },
  // header: {
  //   position: "fixed",
  //   top: 0,
  //   left: 0,
  //   backgroundColor: "red",
  //   width: "100vw",
  //   height: "2em",
  // },
  // main: {
  //   position: "fixed",
  //   top: "3em",
  //   left: 0,
  //   width: "100vw",
  //   height: "calc(100vh - 4em)",
  //   yOverflow: "auto",
  // },
  // footer: {
  //   position: "fixed",
  //   bottom: 0,
  //   left: 0,
  //   backgroundColor: "white",
  //   width: "100vw",
  //   height: "2em",
  // },
  //Flex, but has scrollbar
  // root: {
  //   height: "100%",
  //   width: "100%",
  // },
  // main: {
  //   minHeight: "100%",
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "stretch",
  // },
  // footer: {
  //   flex: 0,
  //   backgroundColor: "white",
  // },

  //____________________________________
  root: {
    position: "relative",
    minHeight: "100vh",
  },

  main: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    paddingBottom: "2.5rem",
  },

  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "2.5rem",
    backgroundColor: "white",
  },
})

const Layout = props => {
  const { classes } = props
  const [menuSelected, setMenu] = useState("/home") // default value is homepage
  const [firebaseHook, setFirebaseHook] = useState() // hook to manipulate

  // importing Firebase at the client side, after build in Server Side
  useEffect(() => {
    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const database = import("firebase/firestore")
    const storage = import("firebase/firebase-storage")

    Promise.all([app, auth, database, storage]).then(values => {
      const firebaseObject = getFirebase(values[0])

      setFirebaseHook(firebaseObject)
    })
  }, [])

  // Monitor user status

  // if user exists than it is because he loged in
  // This isn't done on action because there are multiple ways to loggin or lose session
  if (firebaseHook) {
    firebaseHook.app.auth().onAuthStateChanged(user => {
      if (user) {
        props.registerUserinRedux(user.uid, user.email)
      }
    })
  }

  return (
    <div>
      {/* <ParticlesContainer /> */}
      <div className={classes.root}>
        <Helmet
          title="Lucas' site"
          meta={[
            {
              name: "description",
              content: "Lucas` page",
            },
            {
              name: "keywords",
              content: "personal, developer, researcher",
            },
          ]}
          link={[
            {
              rel: "icon",
              type: "image/png",
              sizes: "16x16",
              href: `${favicon}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "32x32",
              href: `${favicon}`,
            },
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
          ]}
        />

        <div>
          <MenuContext.Provider value={{ menuSelected, setMenu, firebaseHook }}>
            <Header />

            <div className={classes.main}>
              <Transition location={props.location}>
                {props.children}
              </Transition>
            </div>

            <div className={classes.footer}>
              <Footer />
            </div>
          </MenuContext.Provider>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  registerUserinRedux: (uid, user) => dispatch(registerUserInRedux(uid, user)),
})

export default connect(undefined, mapDispatchToProps)(withStyles(style)(Layout))
