import React, { Component } from "react"
import Particles from "react-tsparticles"

class ParticlesContainer extends Component {
  render() {
    return (
      //bubbles
      <Particles params={chars} />
    )
  }
}

export default ParticlesContainer

const bubbles = {
  background: {
    color: {
      value: "#ff5722",
    },
    // image: "url('https://particles.js.org/images/background3.jpg')",
    // position: "50% 50%",
    // repeat: "no-repeat",
    // size: "cover",
  },
  backgroundMask: {
    cover: {
      color: {
        value: {
          //   r: 255,
          //   g: 255,
          //   b: 255,
          r: 224,
          g: 224,
          b: 224,
        },
      },
    },
    enable: true,
  },
  // fullScreen: {
  //   zIndex: 1,
  // },
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "bubble",
        parallax: {
          force: 100,
        },
      },
    },
    modes: {
      bubble: {
        distance: 200,
        duration: 2,
        opacity: 1,
        size: 50,
      },
      grab: {
        distance: 300,
      },
    },
  },
  particles: {
    color: {
      value: "#ff5722",
    },
    links: {
      color: {
        value: "#ff5722",
      },
      distance: 150,
      enable: true,
    },
    move: {
      attract: {
        rotate: {
          x: 600,
          y: 1200,
        },
      },
      enable: true,
      outModes: {
        bottom: "out",
        left: "out",
        right: "out",
        top: "out",
      },
    },
    number: {
      density: {
        enable: true,
      },
      value: 80,
    },
    opacity: {
      animation: {
        speed: 1,
        minimumValue: 0.1,
      },
    },
    size: {
      random: {
        enable: true,
      },
      value: {
        min: 1,
        max: 30,
      },
      animation: {
        speed: 1,
        minimumValue: 0.1,
      },
    },
  },
}

const chars = {
  background: {
    color: {
      value: "#e0e0e0",
    },
    // position: "50% 50%",
    // repeat: "no-repeat",
    // size: "cover",
  },
  //   fullScreen: {
  //     zIndex: 1,
  //   },
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: "push",
      },
      onHover: {
        enable: true,
        mode: "repulse",
        parallax: {
          force: 60,
        },
      },
    },
    modes: {
      bubble: {
        distance: 200,
        duration: 2,
        opacity: 0.8,
        size: 20,
      },
      grab: {
        distance: 100,
      },
    },
  },
  particles: {
    color: {
      value: "#ff5722",
    },
    links: {
      color: {
        value: "#ff5722",
      },
      distance: 150,
      enable: true,
      opacity: 0.4,
    },
    move: {
      attract: {
        rotate: {
          x: 600,
          y: 1200,
        },
      },
      enable: true,
      outModes: {
        bottom: "out",
        left: "out",
        right: "out",
        top: "out",
      },
    },
    number: {
      density: {
        enable: true,
      },
      value: 40,
    },
    opacity: {
      value: {
        min: 0.1,
        max: 0.5,
      },
      animation: {
        enable: true,
        speed: 0.5,
        minimumValue: 0.1,
      },
    },
    shape: {
      options: {
        character: {
          value: ["L", "U", "C", "A", "S"],
          font: "Verdana",
          style: "",
          weight: "400",
          fill: true,
        },
        char: {
          value: ["L", "U", "C", "A", "S"],
          font: "Verdana",
          style: "",
          weight: "400",
          fill: true,
        },
      },
      type: "char",
    },
    size: {
      value: 16,
      animation: {
        speed: 10,
        minimumValue: 10,
      },
    },
    stroke: {
      width: 1,
      color: {
        value: "#ff5722",
        animation: {
          h: {
            count: 0,
            enable: false,
            offset: 0,
            speed: 1,
            sync: true,
          },
          s: {
            count: 0,
            enable: false,
            offset: 0,
            speed: 1,
            sync: true,
          },
          l: {
            count: 0,
            enable: false,
            offset: 0,
            speed: 1,
            sync: true,
          },
        },
      },
    },
  },
}
// {
//     "background": {
//       "color": {
//         "value": "#ffffff"
//       },
//       "image": "url('https://particles.js.org/images/background3.jpg')",
//       "position": "50% 50%",
//       "repeat": "no-repeat",
//       "size": "cover"
//     },
//     "backgroundMask": {
//       "cover": {
//         "color": {
//           "value": {
//             "r": 255,
//             "g": 255,
//             "b": 255
//           }
//         }
//       },
//       "enable": true
//     },
//     "fullScreen": {
//       "zIndex": 1
//     },
//     "interactivity": {
//       "events": {
//         "onClick": {
//           "enable": true,
//           "mode": "push"
//         },
//         "onHover": {
//           "enable": true,
//           "mode": "bubble",
//           "parallax": {
//             "force": 60
//           }
//         }
//       },
//       "modes": {
//         "bubble": {
//           "distance": 400,
//           "duration": 2,
//           "opacity": 1,
//           "size": 100
//         },
//         "grab": {
//           "distance": 400
//         }
//       }
//     },
//     "particles": {
//       "color": {
//         "value": "#ffffff"
//       },
//       "links": {
//         "color": {
//           "value": "#ffffff"
//         },
//         "distance": 150,
//         "enable": true
//       },
//       "move": {
//         "attract": {
//           "rotate": {
//             "x": 600,
//             "y": 1200
//           }
//         },
//         "enable": true,
//         "outModes": {
//           "bottom": "out",
//           "left": "out",
//           "right": "out",
//           "top": "out"
//         }
//       },
//       "number": {
//         "density": {
//           "enable": true
//         },
//         "value": 80
//       },
//       "opacity": {
//         "animation": {
//           "speed": 1,
//           "minimumValue": 0.1
//         }
//       },
//       "size": {
//         "random": {
//           "enable": true
//         },
//         "value": {
//           "min": 1,
//           "max": 30
//         },
//         "animation": {
//           "speed": 40,
//           "minimumValue": 0.1
//         }
//       }
//     }
//   }

// _______________________________________________________________________________________
// {
//     particles: {
//       number: {
//         value: 150,
//         density: {
//           enable: true,
//           value_area: 1803.4120608655228,
//         },
//       },
//       color: {
//         value: "#ff5722",
//       },
//       shape: {
//         type: "circle",
//         stroke: {
//           width: 6,
//           color: "#ff5722",
//         },
//         polygon: {
//           nb_sides: 4,
//         },
//         image: {
//           src: "img/github.svg",
//           width: 100,
//           height: 100,
//         },
//       },
//       opacity: {
//         value: 0.4008530152163807,
//         random: false,
//         anim: {
//           enable: false,
//           speed: 1,
//           opacity_min: 0.1,
//           sync: false,
//         },
//       },
//       size: {
//         value: 1.5,
//         random: true,
//         anim: {
//           enable: false,
//           speed: 5,
//           size_min: 0.1,
//           sync: false,
//         },
//       },
//       line_linked: {
//         enable: true,
//         distance: 0,
//         color: "#ffffff",
//         opacity: 0.3687847739990702,
//         width: 0.6413648243462091,
//       },
//       move: {
//         enable: true,
//         speed: 6,
//         direction: "none",
//         random: false,
//         straight: false,
//         out_mode: "out",
//         bounce: false,
//         attract: {
//           enable: false,
//           rotateX: 600,
//           rotateY: 1200,
//         },
//       },
//     },
//     interactivity: {
//       detect_on: "window",
//       events: {
//         onhover: {
//           enable: true,
//           mode: "repulse",
//         },
//         onclick: {
//           enable: false,
//           mode: "bubble",
//         },
//         resize: true,
//       },
//       modes: {
//         grab: {
//           distance: 400,
//           line_linked: {
//             opacity: 1,
//           },
//         },
//         bubble: {
//           distance: 400,
//           size: 40,
//           duration: 2,
//           opacity: 8,
//           speed: 3,
//         },
//         repulse: {
//           distance: 100,
//           duration: 0.4,
//         },
//         push: {
//           particles_nb: 4,
//         },
//         remove: {
//           particles_nb: 2,
//         },
//       },
//     },
//     retina_detect: true,
//   }
