const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);
    /* Firebase APIs */
    this.app = app;
    this.storage = app.storage;
    this.db = app.firestore();
  }
}

let firebase;

function getFirebase(app, auth, database, storage) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database, storage);
  }

  return firebase;
}

export default getFirebase;
