// Expenses Reducer

const publicationsReducerDefaultState = [
  // {
  //   id: 'healthcom2014',
  //   title: 'A New Architecture for Secure Storage and Sharing of Health Records in the Cloud using Federated Identity Attributes.',
  //   year: '2014',
  //   venue: '2014 ieee 16th International Conference on e-Health Networking, Applications and Services (HealthCom) (pp. 194–199)',
  //   shortVenue: 'HealthCom 2014',
  //   translatedTitle: '',
  //   createdAt: '',
  //   authors: 'Lucas de Melo silva, Roberto Araújo, Felipe Leite da Silva, Eduardo Cerqueira',
  //   doi: 'https://doi.org/10.1109/HealthCom.2014.7001840',
  // },
  // {
  //   id: 'sbsc2014',
  //   title: 'Caixas de Interesses: Um Novo Mecanismo Para a Colaboração Através de Nuvem de Armazenamento de Dados.',
  //   translatedTitle: 'Interest Boxes: A New Mechanism for Collaboration Through Cloud Data Storage',
  //   year: '2014',
  //   venue: 'XI Brazilian Symposium on Collaborative Systems',
  //   shortVenue: 'SBSC 2014',
  //   createdAt: '',
  //   authors: 'Felipe Leite da Silva, Roberto Araújo, Lucas de Melo silva, Nelson Sampaio',
  //   doi: '',
  // },
  // {
  //   id: 'sbseg2013',
  //   title: 'Integrando o Openstack Keystone Com Uma Federação de Identidades.',
  //   translatedTitle: 'Integrating OpenStack Keystone with Federated Identity',
  //   year: '2014',
  //   venue: 'Brazilian Symposium on Information Security and Computer Systems (pp. 102–110)',
  //   shortVenue: 'SBSEG 2013',
  //   createdAt: '',
  //   authors: 'Lucas de Melo silva, Carlos Eduardo Silva, Felipe Leite da Silva, Roberto Araújo',
  //   doi: '',
  // },
];

export default (state = publicationsReducerDefaultState, action) => {
  switch (action.type) {
    case 'ADD_PUBLICATION':
      return [
        ...state,
        action.publication,
      ];
    case 'REMOVE_PUBLICATION':
      return state.filter(({ id }) => id !== action.id);
    case 'EDIT_PUBLICATION':
      return state.map((publication) => {
        if (publication.id === action.id) {
          return {
            ...publication,
            ...action.updates,
          };
        } // else
        return publication;
      });
    case 'SET_PUBLICATIONS':
      return action.publications;
    default:
      return state;
  }
};
