const Menu = [
  {
    label: 'Home',
    pathname: '/',
  },
  {
    label: 'Publications',
    pathname: '/Publications',
  },
  {
    label: 'Projects',
    pathname: '/Projects',
  },

];

export default Menu;
