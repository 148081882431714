import React, { useState } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"

// const useStyles = makeStyles(theme => ({
//   // root: {
//   //   display: 'flex',
//   //   flexDirection: 'column',
//   //   minHeight: '0vh',
//   // },
//   footer: {
//     backgroundColor: theme.palette.background.paper,
//     marginTop: 'auto',
//     // padding: theme.spacing(1, 0),
//     bottom: '0',
//     // borderTop: '1px solid ',
//     // padding: '15px 0',
//     // position: 'absolute',
//     width: '100%',
//   },
// }));

export default function StickyFooter() {
  // const classes = useStyles();

  const [year, setCount] = useState(new Date().getFullYear())

  return (
    <React.Fragment>
      <CssBaseline />
      <footer>
        <Divider variant="inset" />
        <Container maxWidth="lg">
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Copyright © {year} by Lucas Silva
          </Typography>
        </Container>
      </footer>
    </React.Fragment>
  )
}
