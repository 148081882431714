import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { deepOrange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Menu from './Menu';
import MenuContext from '../context/menu-context';
import HeaderUserMenu from './HeaderUserMenu';

const styles = theme => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'white',
  },
  flex: {
    display: 'flex',
    // margin: '0 auto',
    // 'align-items': 'center',
    'justify-content': 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },

  titleContainer: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'flex-wrap': 'wrap',
  },
  title: {
    fontFamily: 'Leckerli One',
    color: deepOrange[500],
    [theme.breakpoints.down('sm')]: {
      'font-size': '.8em',
    },
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  iconButton: {
    float: 'right',
  },
  tabContainer: {
    display: 'flex',
    'justify-content': 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto',
  },
  cvButton: {
    margin: '10px 10px 10px 25px',
    // marginLeft: 25,
    // padding: 0,
    'align-self': 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cvButtonMobile: {
    margin: 10,
  },
  userInfo: {
    // padding: theme.spacing(2),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

const TopBar = (props) => {
  // Hooks
  const { menuSelected, firebaseHook } = useContext(MenuContext);
  const [selectedTabValue, setSelectedTabValue] = useState(0); // default is 0 === home page
  const [menuDrawer, setMenuDrawer] = useState(false);
  const { classes, isAuthenticated } = props;

  // Monitor menuSelected hook, when a page changes,
  // this is the place that detects and sets tab value accordingly
  useEffect(() => {
    if (menuSelected.includes('/Home') || menuSelected === '/') {
      setSelectedTabValue(0);
    }
    if (
      menuSelected.includes('/Publications')
      || menuSelected === '/publications'
    ) {
      setSelectedTabValue(1);
    }
    if (menuSelected.includes('/Projects') || menuSelected === '/projects') {
      setSelectedTabValue(2);
    }
    if (menuSelected === '') {
      // CV redirect
      // TODO: redirect to CV
    }
  }, [menuSelected]);

  const handleChange = (event, passedValue) => {
    setSelectedTabValue(passedValue);
  };

  const mobileMenuOpen = (event) => {
    setMenuDrawer(true);
  };

  const mobileMenuClose = (event) => {
    setMenuDrawer(false);
  };

  const showCurriculum = () => {
    // const folderPath = 'publications/';
    // const fileName = 'healthcom2014.pdf';
    const pdf = firebaseHook
      .storage()
      .ref()
      .child('publications/cv.pdf');

    // var starsRef = storageRef.child('images/stars.jpg');
    // Get the download URL
    pdf
      .getDownloadURL()
      .then((url) => {
        // Insert url into an <img> tag to "download"
        window.open(url);
        // console.log(url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // eslint-disable-next-line default-case
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;

          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={12} className={classes.flex}>
            <div className={classes.titleContainer}>
              <Typography variant="h4" color="inherit" noWrap>
                <div className={classes.title}>Lucas de Melo Silva</div>
              </Typography>
            </div>

            {!props.noTabs && (
              <React.Fragment>
                <div className={classes.iconContainer}>
                  <IconButton
                    onClick={mobileMenuOpen}
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="Menu"
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
                <div className={classes.tabContainer}>
                  <SwipeableDrawer
                    anchor="right"
                    open={menuDrawer}
                    onClose={mobileMenuClose}
                    onOpen={mobileMenuOpen}
                  >
                    <AppBar title="Menu" />
                    <List>
                      {Menu.map((item, index) => (
                        <ListItem
                          component={Link}
                          to={item.pathname}
                          button
                          key={item.label}
                        >
                          <ListItemText primary={item.label} />
                        </ListItem>
                      ))}
                    </List>
                    <Button
                      target="_blank"
                      onClick={() => showCurriculum()}
                      color="secondary"
                      variant="outlined"
                      className={classes.cvButtonMobile}
                    >
                      CV
                    </Button>
                  </SwipeableDrawer>
                  <Tabs
                    value={selectedTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                  >
                    {Menu.map((item, index) => (
                      <Tab
                        key={index}
                        component={Link}
                        to={item.pathname}
                        classes={{ root: classes.tabItem }}
                        label={item.label}
                      />
                    ))}
                  </Tabs>
                </div>
              </React.Fragment>
            )}

            <Button
              target="_blank"
              onClick={() => showCurriculum()}
              color="secondary"
              variant="outlined"
              className={classes.cvButton}
            >
              CV
            </Button>
          </Grid>
        </Grid>

        {isAuthenticated ? (
          <div className={classes.userInfo}>
            <HeaderUserMenu userMail={props.userMail} />
          </div>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  userMail: state.auth.email,
  isAuthenticated: !!state.auth.uid,
});

export default connect(mapStateToProps)(withStyles(styles)(TopBar));
