import { deepOrange } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: deepOrange[700], // Texts
    },
    primary: {
      main: deepOrange[500], // e.g. Butons
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      // '"Lato"',
      "sans-serif",
      "Leckerli One",
    ].join(","),
  },
})

export default theme
