/* eslint-disable no-underscore-dangle */
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import publicationReducer from '../reducers/publications';
// eslint-disable-next-line no-underscore-dangle
const windowGlobal = typeof window !== 'undefined' && window;

const devtools = process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
  ? window.__REDUX_DEVTOOLS_EXTENSION__
    && window.__REDUX_DEVTOOLS_EXTENSION__()
  : f => f;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      publications: publicationReducer,
    }),
    compose(
      applyMiddleware(thunk),
      devtools,
    ),
  );

  return store;
};

// https://github.com/zalmoxisus/remote-redux-devtools

// https://github.com/gatsbyjs/gatsby/issues/6137
