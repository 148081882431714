/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';

import { Link } from 'gatsby';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Poppers from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import MenuContext from '../context/menu-context';

import { startLogout } from '../actions/auth';
import {
  defaultFont,
  dangerColor,
  whiteColor,
} from '../assets/jss/material-dashboard-react';

import dropdownStyle from '../assets/jss/material-dashboard-react/dropdownStyle';
import Button from './CustomButtons/Button';

const headerLinksStyle = theme => ({
  ...dropdownStyle(theme),
  search: {
    '& > div': {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px !important',
      float: 'none !important',
      paddingTop: '1px',
      paddingBottom: '1px',
      padding: '0!important',
      width: '60%',
      marginTop: '40px',
      '& input': {
        color: whiteColor,
      },
    },
  },
  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    margin: '0px',
  },
  buttonLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '10px 15px 0',
      width: '-webkit-fill-available',
      '& svg': {
        width: '14px',
        height: '30px',
        marginRight: '15px',
        marginLeft: '-15px',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '24px',
        lineHeight: '30px',
        width: '24px',
        height: '30px',
        marginRight: '15px',
        marginLeft: '-15px',
      },
      '& > span': {
        justifyContent: 'flex-start',
        width: '100%',
      },
    },
  },
  searchButton: {
    [theme.breakpoints.down('sm')]: {
      top: '-50px !important',
      marginRight: '22px',
      float: 'right',
    },
  },
  margin: {
    zIndex: '4',
    margin: '0',
  },
  searchIcon: {
    width: '17px',
    zIndex: '4',
  },
  notifications: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '2px',
      border: `1px solid ${whiteColor}`,
      right: '4px',
      fontSize: '9px',
      background: dangerColor[0],
      color: whiteColor,
      minWidth: '16px',
      height: '16px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '16px',
      verticalAlign: 'middle',
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '14px',
      marginRight: '8px',
    },
  },
  manager: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'inline-block',
  },
  searchWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
      margin: '10px 15px 0',
    },
    display: 'inline-block',
  },
});

function textEllipsis(str, maxLength, { side = 'end', ellipsis = '...' } = {}) {
  if (str.length > maxLength) {
    switch (side) {
      case 'start':
        return ellipsis + str.slice(-(maxLength - ellipsis.length));
      case 'end':
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
  }
  return str;
}

const HeaderUserMenu = (props) => {
  const [state, setState] = useState({
    openNotifcation: false,
    openProfile: false,
  });

  const [anchorProfile, setAnchorProfile] = useState();
  const { firebaseHook } = useContext(MenuContext);


  const handleToggleNotification = () => {
    setState({ ...state, openNotifcation: !state.openNotifcation });
  };

  const handleCloseNotification = (event) => {
    // if (this.anchorNotification.contains(event.target)) {
    //   return;
    // }
    setState({ ...state, openNotifcation: false });
  };

  const handleToggleProfile = () => {
    setState({ ...state, openProfile: !state.openProfile });
  };

  const handleCloseProfile = (event) => {
    if (anchorProfile.contains(event.target)) {
      return;
    }
    setState({ ...state, openProfile: false });
  };

  const handleLogoutClick = () => {
    props.logout(firebaseHook.app);
    setState({ ...state, openProfile: false });
  };

  const { classes } = props;

  return (
    <div>
      <div className={classes.manager}>
        <Button
          buttonRef={(node) => {
            setAnchorProfile(node);
          }}
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={state.openNotifcation ? 'profile-menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleToggleProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} color="primary" />
        </Button>
        <Poppers
          open={state.openProfile}
          anchorEl={anchorProfile}
          transition
          disablePortal
          className={
            `${classNames({ [classes.popperClose]: !state.openProfile })
            } ${
              classes.popperNav}`
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      className={classes.dropdownItem}
                    >
                      {textEllipsis(props.userMail, 12)}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      <Link
                        style={{ textDecoration: 'none', color: 'black' }}
                        to="/NewPublication"
                      >
                        +Publication
                      </Link>
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleLogoutClick(); }}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
};

// class AdminNavbarLinks extends React.Component {
//   state = {
//     openNotifcation: false,
//     openProfile: false,
//   };

//   handleToggleNotification = () => {
//     this.setState(state => ({ openNotifcation: !state.openNotifcation }));
//   };

//   handleCloseNotification = (event) => {
//     if (this.anchorNotification.contains(event.target)) {
//       return;
//     }
//     this.setState({ openNotifcation: false });
//   };

//   handleToggleProfile = () => {
//     this.setState(state => ({ openProfile: !state.openProfile }));
//   };

//   handleCloseProfile = (event) => {
//     if (this.anchorProfile.contains(event.target)) {
//       return;
//     }
//     this.setState({ openProfile: false });
//   };

// //   logout = () => this.props.logout;

//   handleLogoutClick = (event) => {
//     //   this.logout();
//     this.props.logout();
//       if (this.anchorProfile.contains(event.target)) {
//         return;
//       }
//       this.setState({ openProfile: false });
//   }

//   render() {
//     const { classes } = this.props;
//     const { openNotifcation, openProfile } = this.state;
//     return (

//     );
//   }
// }

HeaderUserMenu.propTypes = {
  classes: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  logout: firebaseApp => dispatch(startLogout(firebaseApp)),
});

export default connect(undefined, mapDispatchToProps)(withStyles(headerLinksStyle)(HeaderUserMenu));
