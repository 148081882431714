/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */

export const registerUserInRedux = (uid, email) => {
  return {
    type: 'LOGIN',
    uid,
    email,
  };
};

export const startGoogleLogin = (firebaseApp) => {
  return () => {
    return firebaseApp.auth().signInWithPopup(new firebaseApp.auth.GoogleAuthProvider());
  };
// Don't register user in redux here because there are other forms of login (such as using email + password)
};

export const logout = () => {
  console.log('in logout');
  return {
    type: 'LOGOUT',
  };
};

export const startLogout = firebaseApp => (dispatch) => {
  console.log(dispatch);
  console.log(firebaseApp);
  return firebaseApp.auth().signOut()
    .then(() => {
      console.log('Signed Out');
      dispatch(logout());
    }, (error) => {
      console.error('Sign Out Error', error);
    });
};
